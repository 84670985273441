import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LoadExternalScript from "../components/load-external-script"

const GamePost = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.fluid.src
    : ""
  const imgPos = frontmatter.featuredImagePosition
    ? frontmatter.featuredImagePosition
    : "50% 50%"
  return (
    <Layout className="page">
      <SEO
        title={frontmatter.title}
        description={
          frontmatter.description ? frontmatter.description : excerpt
        }
        image={Image}
        article={true}
      />
      <article className="game-post">
        <header className="featured-banner">
          <section className="article-header">
            <h1>{frontmatter.title}</h1>
            <time>{frontmatter.date}</time>
          </section>
        </header>

        <div className="featured-image">
          <Img
            fluid={frontmatter.featuredImage.childImageSharp.fluid}
            alt={frontmatter.title + " - Featured image"}
            style={{ maxHeight: "100px" }}
            imgStyle={{
              objectPosition: imgPos,
            }}
          />
        </div>

        {frontmatter.gameSrc && (
          <div>
            <LoadExternalScript
              src={`/assets/${frontmatter.gameSrc.relativePath}`}
              id={`game-${frontmatter.title}`}
            />
          </div>
        )}
        <div
          className="game-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </Layout>
  )
}

export default GamePost

export const pageQuery = graphql`
  query GamePostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        blurb
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        featuredImagePosition
        gameSrc {
          relativePath
        }
      }
    }
  }
`
