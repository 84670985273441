import React, { Component } from "react"
import { Helmet } from "react-helmet"

class LoadExternalScript extends Component {
  state = {
    isLoaded: false,
    scriptLoaded: false,
  }
  componentDidMount() {
    this.setState({ isLoaded: true })
  }
  componentDidUpdate() {
    if (this.state.isLoaded && !this.state.scriptLoaded) {
      this.setState({ scriptLoaded: true })
    }
  }
  render() {
    const { src, id } = this.props

    return (
      <div>
        <Helmet>
          {this.state.isLoaded && src && (
            <script
              type="text/javascript"
              key={`script-${id}`}
              src={src}
            ></script>
          )}
        </Helmet>
        <div className="game-container">
          <canvas id="webgl" className="game-canvas" globalevents="0" />
          {!this.state.scriptLoaded && (
            <div className="game-loader">
              <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default LoadExternalScript
